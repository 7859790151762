<template>
  <div>
    <!-- Show the create form -->
    <v-dialog
      :value="value"
      @input="$emit('input', $event)"
      max-width="600"
      :persistent="isMakingRequest"
    >
      <v-card :loading="isMakingRequest">
        <v-card-title class="d-flex justify-space-between align-center primary white--text pb-4">
          <div>
            Competitor Check
          </div>

          <div class="d-flex justify-center align-center">
            <!-- If there are more than one influencers -->
            <div
              v-if="influencers.length > 1"
              class="text-subtitle-2"
            >
              {{ influencers.length }} Influencers
            </div>

            <template
              v-if="influencers.length === 0"
            >
              <span class="mr-4 text-subtitle-2">
                Multiple Query?
              </span>

              <v-switch
                v-model="isMultiple"
                :disabled="isMakingRequest"
                :readonly="influencers.length > 0"
                class="mt-0 mr-0"
                color="accent"
                hide-details
                inset
              ></v-switch>
            </template>
          </div>
        </v-card-title>

        <v-card-text class="pt-6">
          <div
            v-if="influencer"
            class="d-flex align-center"
          >
            <v-img
              height="30"
              width="30"
              max-width="30"
              class="mr-3"
              :src="platformIconMap[influencer.platform]"
            />

            <profile-chip
              :data="influencer.preview || influencer"
              :platform="influencer.platform"
            />
          </div>

          <div
            v-else
            class="d-flex"
          >
            <!-- show the input for searching terms -->
            <profile-selector
              @change="handleProfileChange"
              :platform="selectedPlatform"
              :use-combobox="true"
              :hide-no-data="true"
              type="search"
              label="Search Profile"
              class="rounded-tr-0 rounded-br-0"
              outlined
            />

            <!-- the select options for different platforms -->
            <platform-selector
              :value="selectedPlatform"
              @input="handlePlatformChange"
              :show-youtube="false"
              class="rounded-tl-0 rounded-bl-0"
              outlined
            />
          </div>

          <!-- Show the title input -->
          <v-text-field
            v-model="formData.title"
            label="Report Title"
            placeholder="Optional"
            @input="$v.formData.title.$touch()"
            @blur="$v.formData.title.$touch()"
            :hide-details="!$v.formData.title.$anyError"
            :error-messages="$v.formData.title.$anyError ? ['Please enter a title under 100 characters'] : null"
            class="my-6"
            outlined
          ></v-text-field>

          <!-- Show the time period selector -->
          <v-select
            v-model="formData.monthDuration"
            label="Time Period"
            :items="monthDurationOptions"
            hide-details
            class="mb-6"
            outlined
          ></v-select>

          <!-- Show the input for queries -->
          <v-combobox
            v-model="formData.queries"
            label="Search queries"
            :allow-overflow="true"
            placeholder="#hashtag @mention keyword, press enter to separate them"
            hint="Enter hashtags, mentions or keywords to search for"
            :error-messages="$v.formData.queries.$anyError ? [getQueriesErrorMessages()] : null"
            multiple
            outlined
            small-chips
            deletable-chips
          ></v-combobox>
        </v-card-text>

        <v-card-actions>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip
                label
                color="primary"
                v-on="on"
              >
                <v-icon
                  left
                  small
                >
                  account_balance
                </v-icon>

                {{ nFormatter(availableModuleUsage) }} Reports
              </v-chip>
            </template>

            <span>
              You have {{ availableModuleUsage }} reports available to generate
            </span>
          </v-tooltip>

          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="$emit('input', false)"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            :loading="isMakingRequest"
            :disabled="isMakingRequest || availableModuleUsage <= 0"
            @click="handleSubmit"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import helper functions
import { required, maxLength } from "vuelidate/lib/validators"

// Import children components
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ '@/blocks/common/ProfileChip')
const ProfileSelector = () => import(/* webpackChunkName: "profile-selector" */ "@/blocks/common/selectors/ProfileSelector.vue")
const PlatformSelector = () => import(/* webpackChunkName: "platform-selector" */ "@/blocks/common/selectors/PlatformSelector.vue")

// Define function for initial form data
const initialFormData = () => ({
  title: "",
  queries: [],
  monthDuration: 1
})

// Export the SFC
export default {
  // Name of the component
  name: "CompetitorCheckForm",

  // Register the components
  components: {
    ProfileChip,
    ProfileSelector,
    PlatformSelector
  },

  // Define the props
  props: {
    value: {
      type: Boolean,
      default: false
    },

    source: {
      type: String,
      default: "self"
    },

    influencer: {
      type: Object,
      required: false,
      default: null
    },

    influencers: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  // Define local data variables
  data: () => ({
    isMakingRequest: false,

    formData: initialFormData(),
    selectedInfluencers: [],
    isMultiple: false,

    monthDurationOptions: [
      { text: "1 month", value: 1 },
      { text: "3 months", value: 3 },
      { text: "6 months", value: 6 },
      { text: "12 months", value: 12 }
    ]
  }),

  // Define local computable properties
  computed: {
    /**
     * Compute the available module usage
     *
     * @returns {Number}
     */
    availableModuleUsage() {
      return this.$store.getters["auth/availableModuleUsage"]("competitor-check")
    },

    /**
     * Get the selected platform
     */
    selectedPlatform: {
      get() {
        return this.$store.getters["competitorCheck/selectedPlatform"]
      },

      set(value) {
        this.$store.dispatch("competitorCheck/updateSelectedPlatform", value)
      }
    },

    /**
     * Get the selected influencer
     */
    selectedInfluencer: {
      get() {
        return this.$store.getters["competitorCheck/selectedInfluencer"]
      },

      set(value) {
        this.$store.dispatch("competitorCheck/updateSelectedInfluencer", value)
      }
    },
  },

  // Define the validations
  validations: {
    formData: {
      title: {
        maxLength: maxLength(100)
      },

      queries: {
        required
      }
    }
  },

  // Define watcher properties
  watch: {
    // Track changes for "influencers" prop
    influencers: {
      deep: true,
      handler(influencers) {
        // If there are no influencers
        if (influencers.length === 0) {
          this.selectedInfluencers = []
          this.isMultiple = false
        }
        // Otherwise
        else {
          this.selectedInfluencers = [...influencers]
          this.isMultiple = true
        }

        // Reset the form data
        this.formData = initialFormData()
        this.$v.$reset()
      }
    }
  },

  // Define local method functions
  methods: {
    // Handle the profile change
    handleProfileChange(profile) {
      this.selectedInfluencer = profile
    },

    // Handle the platform change
    handlePlatformChange(platform) {
      this.selectedPlatform = platform
    },

    /**
     * Get the error message for hashtag input
     *
     * @returns {String}
     */
    getQueriesErrorMessages() {
      // If the user has not entered any queries
      if (this.formData.queries.length === 0) {
        // Return the error message
        return "Please enter at least one keyword"
      }

      // If the user has entered more than 50 queries
      if (this.formData.queries.length > 50) {
        // Return the error message
        return "Please enter at most 50 search terms"
      }

      // If the user has entered invalid queries
      if (this.$v.formData.queries.$anyError) {
        // Return the error message
        return "Please enter valid search terms"
      }

      // Otherwise, return null
      return null
    },

    /**
     * Handle the form submission
     *
     * @return {void}
     */
    async handleSubmit() {
      // If we're already making a request
      if (this.isMakingRequest) {
        return
      }

      // Validate the form
      await this.$v.$touch()

      // Check if there's no influencer
      if (!this.selectedInfluencer && !this.influencer) {
        // Show an error toast
        this.$store.dispatch("toasts/add", { text: "Please select an influencer" })

        // Return early
        return
      }

      // Check if the form is invalid
      if (this.$v.$invalid) {
        // Show an error toast
        this.$store.dispatch("toasts/add", { text: "Please fix the errors in the form" })

        // Return early
        return
      }

      // If there's queries error messages
      if (this.getQueriesErrorMessages()) {
        // Show an error toast
        this.$store.dispatch("toasts/add", { text: this.getQueriesErrorMessages() })

        // Return early
        return
      }

      // Otherwise, set a global loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Make the network request
      try {
        // Get the response
        await axios({
          url: "/api/competitor-checks",
          method: "POST",
          data: {
            title: this.formData.title,
            queries: this.formData.queries,
            month_duration: this.formData.monthDuration,

            platform: this.influencer ? this.influencer.platform : this.selectedPlatform,
            username: this.influencer ? this.influencer.username : this.selectedInfluencer.username || this.selectedInfluencer,

            source: this.source,
            sharing_access: "self"
          }
        })

        // Refresh the user's balance
        fetchProfile()

        // Show a success toast
        this.$store.dispatch("toasts/add", { text: "Report queued! We'll notify you once it's ready." })

        // Emit the close event
        this.$emit("input", false)
        this.$emit("created")

        // Reset the form data
        this.formData = initialFormData()
        this.$v.$reset()
      } catch (error) {
        // Log the error
        logger({ type: "CompetitorCheck/Form/Create Error", error })

        // Show an error toast
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred, please try again!" })
      } finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isMakingRequest = false
      }
    }
  }
}
</script>
